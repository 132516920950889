import React, { useEffect } from "react";
import "./styles.scss";
import { MonthPicker } from "widgets/datepickers/month-picker";
import { BigSkeleton } from "../../ui";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { toJS } from "mobx";
import { FundHeader } from "./components/fundHeader";
import FundLeftTable from "./components/fundLeftTable";
import FundRightTable from "./components/rightTable/fundRightTable";
import FundModal from "./components/fundModal";
import { EmptyTableBody } from "../../ui/empty-table-body";
//todo loader, second array, outside table click, save call
export const SalaryFundPage = observer(() => {
  const { RootStore } = useStore();
  const { SalaryFundStore } = RootStore;
  const {
    isLoadingFund,
    payrollData,
    getPayroll,
    getHolds,
    getContracts,
    selectedDate,
    setSelectedDate,
  } = SalaryFundStore;

  useEffect(() => {
    getPayroll();
  }, [selectedDate]);

  useEffect(() => {
    getPayroll();
    getContracts();
    getHolds();
  }, []);

  return (
    <div className="fund__wrapper">
      <FundHeader />
      <div className="fund__main">
        <div className="fund__filters">
          <MonthPicker date={selectedDate} dateSetter={setSelectedDate} />
        </div>
        <div className="fund-table__wrapper">
          {!isLoadingFund ? (
            toJS(payrollData) && toJS(payrollData).length > 0 ? (
              <>
                <FundLeftTable />
                <FundRightTable />
              </>
            ) : (
              <EmptyTableBody />
            )
          ) : (
            <BigSkeleton />
          )}
        </div>
      </div>
      <FundModal />
    </div>
  );
});
