import { useStore } from "store";
import { PeriodPicker } from "widgets/datepickers/period-picker";

export const PeriodFilterBS = () => {
  const { RootStore } = useStore();
  const { BankStatementsStore } = RootStore;
  const { periodStartBS, periodEndBS, handlePeriodChangeBS } =
    BankStatementsStore;

  return (
    <PeriodPicker
      periodStart={periodStartBS}
      periodEnd={periodEndBS}
      periodSetter={handlePeriodChangeBS}
      isFilter
    />
  );
};
