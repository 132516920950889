import { observer } from "mobx-react-lite";
import { useStore } from "store";

export const StatementDetails = observer(() => {
  const {
    RootStore: {
      BankStatementsStore: { detailsData },
    },
  } = useStore();

  return detailsData !== null ? (
    <div className="statement-details">
      <div className="statement-details__item">
        <p className="statement-details__title">Дата:</p>
        <p className="statement-details__content">{detailsData.date}</p>
      </div>
      <div className="statement-details__item">
        <p className="statement-details__title">Начало дня:</p>
        <p className="statement-details__content">{detailsData.startBal}</p>
      </div>
      <div className="statement-details__item">
        <p className="statement-details__title">Конец дня:</p>
        <p className="statement-details__content">{detailsData.finalBal}</p>
      </div>
      <div className="statement-details__item">
        <p className="statement-details__title">Оборот за день:</p>
        <p className="statement-details__content">{detailsData.turnover}</p>
      </div>
    </div>
  ) : (
    <></>
  );
});
