import React from "react";

export const DeleteContractIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 7.5H8.75V15H7.5V7.5ZM11.25 7.5H12.5V15H11.25V7.5Z"
      fill="currentColor"
    />
    <path
      d="M2.5 3.75V5H3.75V17.5C3.75 17.8315 3.8817 18.1495 4.11612 18.3839C4.35054 18.6183 4.66848 18.75 5 18.75H15C15.3315 18.75 15.6495 18.6183 15.8839 18.3839C16.1183 18.1495 16.25 17.8315 16.25 17.5V5H17.5V3.75H2.5ZM5 17.5V5H15V17.5H5ZM7.5 1.25H12.5V2.5H7.5V1.25Z"
      fill="currentColor"
    />
  </svg>
);

export const ContractIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3125 1.875C8.24119 1.87727 6.25538 2.70111 4.79074 4.16574C3.32611 5.63038 2.50227 7.61619 2.5 9.6875V16.2758C2.50041 16.6003 2.62953 16.9115 2.85902 17.141C3.08852 17.3705 3.39966 17.4996 3.72422 17.5H10.3125C12.3845 17.5 14.3716 16.6769 15.8368 15.2118C17.3019 13.7466 18.125 11.7595 18.125 9.6875C18.125 7.6155 17.3019 5.62836 15.8368 4.16323C14.3716 2.6981 12.3845 1.875 10.3125 1.875ZM6.875 10.9375C6.68958 10.9375 6.50832 10.8825 6.35415 10.7795C6.19998 10.6765 6.07982 10.5301 6.00886 10.3588C5.93791 10.1875 5.91934 9.99896 5.95551 9.8171C5.99169 9.63525 6.08098 9.4682 6.21209 9.33709C6.3432 9.20598 6.51025 9.11669 6.6921 9.08051C6.87396 9.04434 7.06246 9.06291 7.23377 9.13386C7.40507 9.20482 7.55149 9.32498 7.6545 9.47915C7.75752 9.63332 7.8125 9.81458 7.8125 10C7.8125 10.2486 7.71373 10.4871 7.53791 10.6629C7.3621 10.8387 7.12364 10.9375 6.875 10.9375ZM10.3125 10.9375C10.1271 10.9375 9.94582 10.8825 9.79165 10.7795C9.63748 10.6765 9.51732 10.5301 9.44636 10.3588C9.37541 10.1875 9.35684 9.99896 9.39301 9.8171C9.42919 9.63525 9.51848 9.4682 9.64959 9.33709C9.7807 9.20598 9.94775 9.11669 10.1296 9.08051C10.3115 9.04434 10.5 9.06291 10.6713 9.13386C10.8426 9.20482 10.989 9.32498 11.092 9.47915C11.195 9.63332 11.25 9.81458 11.25 10C11.25 10.2486 11.1512 10.4871 10.9754 10.6629C10.7996 10.8387 10.5611 10.9375 10.3125 10.9375ZM13.75 10.9375C13.5646 10.9375 13.3833 10.8825 13.2292 10.7795C13.075 10.6765 12.9548 10.5301 12.8839 10.3588C12.8129 10.1875 12.7943 9.99896 12.8305 9.8171C12.8667 9.63525 12.956 9.4682 13.0871 9.33709C13.2182 9.20598 13.3852 9.11669 13.5671 9.08051C13.749 9.04434 13.9375 9.06291 14.1088 9.13386C14.2801 9.20482 14.4265 9.32498 14.5295 9.47915C14.6325 9.63332 14.6875 9.81458 14.6875 10C14.6875 10.2486 14.5887 10.4871 14.4129 10.6629C14.2371 10.8387 13.9986 10.9375 13.75 10.9375Z"
      fill="currentColor"
    />
  </svg>
);

export const DotIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7" y="7" width="6" height="6" rx="3" fill="#B4BAC6" />
  </svg>
);
