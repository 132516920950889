import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FC,useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import { EInputStyleVariant,Input } from 'ui-new/input';
import { Modal } from 'widgets/modal';
import { ModalButtons } from 'widgets/modal/buttons';
import * as yup from 'yup';

interface FormValue {
  dkk: number | null;
}

const schema = yup.object({
  dkk: yup.number().typeError(() => ValidationMessages.get(ValidationTypes.number)).min(0,({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`).max(999,({ max }) => ValidationMessages.get(ValidationTypes.max) + ` ${max}`)
    .required(ValidationMessages.get(ValidationTypes.required)).nullable(),
});

interface DkkModalProps {
  onClose: () => void;
}

const onKeyDown = (e: any) => {
  if (e.code === 'Minus' || /[\+\-\.\,]$/.test(e.key)) e.preventDefault();
};

const DkkModalComponent: FC<DkkModalProps> = ({ onClose }) => {
  const { RootStore: { HeaderStore: { profileData,fetchSetDkk } } } = useStore();
  const dkk = useMemo(() => {
    if (!profileData) return null;
    if (typeof profileData.dkk === 'number') return profileData.dkk;
  },[toJS(profileData)]);
  const { control,handleSubmit } = useForm<FormValue>({ resolver: yupResolver<FormValue>(schema),defaultValues: { dkk } });

  const onCloseHandler = () => {
    onClose();
  };

  const onSubmit = async (values: FormValue) => {
    if (values.dkk === null) return;
    const success = await fetchSetDkk(Number(values.dkk));
    if (success)
      onClose();
  };


  return (<Modal title='Настройка часов по ДКК' onClose={onCloseHandler}
    maxWidth='480px' minWidth='480px'>
    <div>
      <Input variant={EInputStyleVariant.basicInput} control={control} type='number' name='dkk' label='Установи количество часов' placeholder='Установи количество часов' inputProps={{ onKeyDown }} />
      <div style={{ display: 'flex',justifyContent: 'end',marginTop: '13px' }}>
        <ModalButtons primaryButtonLabel={'Сохранить'} onPrimaryButtonClick={handleSubmit(onSubmit)}
          secondaryButtonLabel='Отменить' onSecondaryButtonClick={onCloseHandler}
        />
      </div>
    </div>
  </Modal>);
};

export const DkkModal = observer(DkkModalComponent);