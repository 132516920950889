import React,{ InputHTMLAttributes } from "react";
import { observer } from "mobx-react-lite";
import cls from "./styles.module.scss";
import { FieldValues,useController,UseControllerProps } from 'react-hook-form';

export enum EInputStyleVariant {
  basicInput = "basicInput",
  searchInput = "searchInput",
}

export enum EInputVariant {
  text = "text",
  password = "password",
  time = "time",
}

// interface IInputType {
//   type?: EInputVariant;
//   variant?: EInputStyleVariant;
//   placeholder?: string;
//   maxWidth?: string;
//   height?: string;
//   font?: EFontVariant;
//   borderRadius?: string;
//   value?: string;
//   autoFocus?: boolean;
//   disabled?: boolean;
//   width?: string;
//   required?: boolean;
//   onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
//   onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
//   onKeyDown?: () => void;
//   onBlur?: () => void;
//   dataDate?: string;
//   dataLocation?: string;
//   className?: string;
//   pattern?: string;
//   name?: string;
//   withReset?: boolean;
//   onReset?: () => void;
//   maxLength?: number;
//   min?: string;
//   max?: string;
// }

interface IInputType<T extends FieldValues> extends UseControllerProps<T> {
  variant?: EInputStyleVariant;
  className?: string;
  label?: string;
  placeholder?: string;
  type: React.HTMLInputTypeAttribute;

  width?: string;

  inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,HTMLInputElement>;
}

const InputComponent = <T extends FieldValues>({ variant,className,label,placeholder,type,width,inputProps,...props }: IInputType<T>) => {
  const { field,fieldState } = useController(props);
  const { name } = props;

  return (
    <div className={cls["inputField"]}>
      {label && <label htmlFor={name} className={cls['label']}>{label}</label>}
      <input style={{ width }} type={type} {...field} id={name} placeholder={placeholder} className={`${variant ? cls[variant] : ''} ${className ? cls[className] : ''} ${fieldState.error ? cls['basicInputError'] : ''} `}
        {...inputProps} />
      <p className={cls['errorText']}>{fieldState.error?.message}</p>
    </div>
  );
};

export const Input = observer(InputComponent);;