import React, { useEffect, useRef, useState } from 'react';
import { toJS } from 'mobx';
import { useStore } from '../../../../store';
import FundRightTableHeader from './fundRightTableHeader';
import FundRightTableFooter from './fundRightTableFooter';
import { observer } from 'mobx-react-lite';
import { ContractIcon } from '../../icons';

const FundRightTable = observer(() => {
  const { RootStore } = useStore();
  const { SalaryFundStore } = RootStore;
  const {
    payrollData,
    isButtonVisibleFund,
    setPayrollData,
    selectedDate,
    setPickedTitle,
    setPickedEmployeeId,
    getUserContracts,
    setIsContractsModalPicked
  } = SalaryFundStore;

  console.log(
    selectedDate,
    new Date(),
    selectedDate.setHours(0, 0, 0, 0),
    new Date().setHours(0, 0, 0, 0)
  );

  // клики по "ячейкам"
  const [pickedIdx, setPickedIdx] = useState<string | null>(null);
  const [selectedIdx, setSelectedIdx] = useState<string | null>(null);

  const handleTableElementClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: string
  ) => {
    if (!isButtonVisibleFund) return;
    if (event.detail === 1) {
      if (index === selectedIdx) {
        setPickedIdx(index);
      } else {
        setSelectedIdx(index);
        setPickedIdx(null);
      }
    }

    if (event.detail === 2) {
      if (index === pickedIdx) {
        setPickedIdx(null);
      } else {
        setPickedIdx(index);
      }
    }
  };

  // изменения данных в "ячейке"
  const handleTableInputChange = (event: any, index: number, field: string) => {
    const { value } = event.target as HTMLInputElement;
    if (value === '') {
      const newData: any = [...toJS(payrollData)];
      newData[index] = {
        ...newData[index],
        [field]: {
          ...newData[index][field],
          sum: 0
        }
      };
      setPayrollData(newData);
    }

    if (/^\d+(\.\d{0,2})?$/.test(value)) {
      const newData: any = [...payrollData];
      newData[index] = {
        ...newData[index],
        [field]: {
          ...newData[index][field],
          sum: value.endsWith('.') ? value : +value
        }
      };
      setPayrollData(newData);
    }
  };

  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      console.log(tableRef, event);
      if (
        tableRef.current &&
        !tableRef.current.contains(event.target as Node)
      ) {
        setSelectedIdx(null);
        setPickedIdx(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside, {
      capture: true
    });

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, {
        capture: true
      });
    };
  }, []);

  return (
    <div className='fund-table__container-wrapper' ref={tableRef}>
      <div className='fund-table__container'>
        <FundRightTableHeader />
        <div className='fund-table__body'>
          {toJS(payrollData) &&
            toJS(payrollData).map((object: any, index: number) => (
              <div
                className='fund-table__body-row'
                key={object.id + index}
                id={object.id + index}
              >
                {/*temp - scfin 946*/}
                {/*<div*/}
                {/*  className="fund-table__body-element f112"*/}
                {/*  key={`${object.id + index}-${3}`}*/}
                {/*  id={`${object.id + index}-${3}`}*/}
                {/*>*/}
                {/*  <span>{object.paid.sum}</span>*/}
                {/*</div>*/}
                <div
                  className='fund-table__body-element f100'
                  key={`${object.id + index}-${4}`}
                  id={`${object.id + index}-${4}`}
                >
                  <span>{object.planHour.sum}</span>
                </div>
                <div
                  className={`fund-table__body-element  f100 ${
                    selectedIdx === `${object.id + index}-${5}`
                      ? 'selected'
                      : ''
                  } ${
                    pickedIdx === `${object.id + index}-${5}` ? 'picked' : ''
                  }`}
                  key={`${object.id + index}-${5}`}
                  id={`${object.id + index}-${5}`}
                >
                  <span>{object.factHour.sum}</span>
                </div>
                <div
                  className='fund-table__body-element f140'
                  key={`${object.id + index}-${6}`}
                  id={`${object.id + index}-${6}`}
                >
                  <span>{object.reward.sum}</span>
                </div>
                <div
                  className={`fund-table__body-element editable f132 ${
                    selectedIdx === `${object.id + index}-${7}`
                      ? 'selected'
                      : ''
                  } ${
                    pickedIdx === `${object.id + index}-${7}` ? 'picked' : ''
                  }`}
                  key={`${object.id + index}-${7}`}
                  id={`${object.id + index}-${7}`}
                  onClick={(event) =>
                    handleTableElementClick(event, `${object.id + index}-${7}`)
                  }
                >
                  <input
                    className={`fund-table__body-element input`}
                    value={object.managerBonus.sum?.toString()}
                    pattern='^\d*(\.\d{0,2})?$'
                    onChange={(event) =>
                      handleTableInputChange(event, index, 'managerBonus')
                    }
                  />
                  <span>{object.managerBonus.sum}</span>
                </div>
                <div
                  className='fund-table__body-element f164'
                  key={`${object.id + index}-${8}`}
                  id={`${object.id + index}-${8}`}
                >
                  <span>{object.revenueBonus.sum}</span>
                </div>
                <div
                  className='fund-table__body-element f100'
                  key={`${object.id + index}-${9}`}
                  id={`${object.id + index}-${9}`}
                >
                  <span>{object.contracts.sum}</span>
                  <div
                    className='fund-table__icon'
                    onClick={() => {
                      RootStore.setModalIsActive(true);
                      setPickedTitle('Контракты');
                      setPickedEmployeeId(object.id);
                      getUserContracts(object.id);
                      setIsContractsModalPicked(true);
                    }}
                    data-title={
                      object.contracts.items.title
                        ? ` ${object.contracts.items.title}`
                        : undefined
                    }
                  >
                    <ContractIcon />
                  </div>
                </div>
                <div
                  className={`fund-table__body-element editable f100 ${
                    selectedIdx === `${object.id + index}-${10}`
                      ? 'selected'
                      : ''
                  } ${
                    pickedIdx === `${object.id + index}-${10}` ? 'picked' : ''
                  }`}
                  key={`${object.id + index}-${10}`}
                  id={`${object.id + index}-${10}`}
                  onClick={(event) =>
                    handleTableElementClick(event, `${object.id + index}-${10}`)
                  }
                >
                  <input
                    className={`fund-table__body-element input`}
                    value={object.tax.sum?.toString()}
                    pattern='^\d*(\.\d{0,2})?$'
                    onChange={(event) =>
                      handleTableInputChange(event, index, 'tax')
                    }
                  />
                  <span>{object.tax.sum}</span>
                </div>
                <div
                  className={`fund-table__body-element editable f100 ${
                    selectedIdx === `${object.id + index}-${12}`
                      ? 'selected'
                      : ''
                  } ${
                    pickedIdx === `${object.id + index}-${12}` ? 'picked' : ''
                  }`}
                  key={`${object.id + index}-${12}`}
                  id={`${object.id + index}-${12}`}
                  onClick={(event) =>
                    handleTableElementClick(event, `${object.id + index}-${12}`)
                  }
                >
                  <input
                    className={`fund-table__body-element input`}
                    value={object.OPS.sum?.toString()}
                    pattern='^\d*(\.\d{0,2})?$'
                    onChange={(event) =>
                      handleTableInputChange(event, index, 'OPS')
                    }
                  />
                  <span>{object.OPS.sum}</span>
                </div>
                <div
                  className={`fund-table__body-element editable f100 ${
                    selectedIdx === `${object.id + index}-${13}`
                      ? 'selected'
                      : ''
                  } ${
                    pickedIdx === `${object.id + index}-${13}` ? 'picked' : ''
                  }`}
                  key={`${object.id + index}-${13}`}
                  id={`${object.id + index}-${13}`}
                  onClick={(event) =>
                    handleTableElementClick(event, `${object.id + index}-${13}`)
                  }
                >
                  <input
                    className={`fund-table__body-element input`}
                    value={object.OMS.sum?.toString()}
                    pattern='^\d*(\.\d{0,2})?$'
                    onChange={(event) =>
                      handleTableInputChange(event, index, 'OMS')
                    }
                  />
                  <span>{object.OMS.sum}</span>
                </div>
                <div
                  className={`fund-table__body-element editable f100 ${
                    selectedIdx === `${object.id + index}-${14}`
                      ? 'selected'
                      : ''
                  } ${
                    pickedIdx === `${object.id + index}-${14}` ? 'picked' : ''
                  }`}
                  key={`${object.id + index}-${14}`}
                  id={`${object.id + index}-${14}`}
                  onClick={(event) =>
                    handleTableElementClick(event, `${object.id + index}-${14}`)
                  }
                >
                  <input
                    className={`fund-table__body-element input`}
                    value={object.VNiM.sum?.toString()}
                    pattern='^\d*(\.\d{0,2})?$'
                    onChange={(event) =>
                      handleTableInputChange(event, index, 'VNiM')
                    }
                  />
                  <span>{object.VNiM.sum}</span>
                </div>
                <div
                  className={`fund-table__body-element editable f100 ${
                    selectedIdx === `${object.id + index}-${15}`
                      ? 'selected'
                      : ''
                  } ${
                    pickedIdx === `${object.id + index}-${15}` ? 'picked' : ''
                  }`}
                  key={`${object.id + index}-${15}`}
                  id={`${object.id + index}-${15}`}
                  onClick={(event) =>
                    handleTableElementClick(event, `${object.id + index}-${15}`)
                  }
                >
                  <input
                    className={`fund-table__body-element input`}
                    value={object.CoHC.sum?.toString()}
                    pattern='^\d*(\.\d{0,2})?$'
                    onChange={(event) =>
                      handleTableInputChange(event, index, 'CoHC')
                    }
                  />
                  <span>{object.CoHC.sum}</span>
                </div>
              </div>
            ))}
        </div>
        <FundRightTableFooter />
      </div>
    </div>
  );
});

export default FundRightTable;
