import React from "react";
import { observer } from "mobx-react-lite";

const FundRightTableHeader = observer(() => {
  return (
    <div className="fund-table__header">
      {/*<div className="fund-table__header-element f112">Выплачено, ₽</div>*/}
      <div className="fund-table__header-element f100">План, часы</div>
      <div className="fund-table__header-element f100">Факт, часы</div>
      <div className="fund-table__header-element f140">Вознаграждение, ₽</div>
      <div className="fund-table__header-element f132">Премия от упр., ₽</div>
      <div className="fund-table__header-element f164">
        Премия от выручки., ₽
      </div>
      <div className="fund-table__header-element f100">Контракты, ₽</div>
      <div className="fund-table__header-element f100">НДФЛ, ₽</div>
      <div className="fund-table__header-element f100">ОПС, ₽</div>
      <div className="fund-table__header-element f100">ОМС, ₽</div>
      <div className="fund-table__header-element f100">ВНиМ, ₽</div>
      <div className="fund-table__header-element f100">СоНС, ₽</div>
    </div>
  );
});

export default FundRightTableHeader;
