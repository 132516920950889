import { SortOrder } from "assets/types";

/** принимает массив с выписками, фильтрует в соотвествии с выбранными фильтрами, 
    сортирует в соответствии с выбранным параметром и направлением сортировки 
    и возвращает обработанный массив  */
export function proccessDataBS(
  dataBS: any[],
  pickedPayTypesBS: any[],
  pickedOperTypesBS: any[],
  pickedContragentsBS: any[],
  searchTextBS: string,
  sortParamBS: "date" | "docId" | "contragent" | null,
  sortOrderBS: SortOrder,
) {
  let filtered = [...dataBS];

  // фильтруем
  if (pickedOperTypesBS.length > 0) {
    filtered = filterByOperTypeBS(filtered, pickedOperTypesBS);
  }
  if (pickedPayTypesBS.length > 0) {
    filtered = filterByPayTypeBS(filtered, pickedPayTypesBS);
  }
  if (pickedContragentsBS.length > 0) {
    filtered = filterByContragentsBS(filtered, pickedContragentsBS);
  }
  if (searchTextBS !== "") {
    filtered = filterBySearchTextBS(filtered, searchTextBS);
  }

  // сортируем и возвращаем
  switch (sortParamBS) {
    case "contragent": {
      return sortByContragentBS(filtered, sortOrderBS);
    }
    case "date": {
      return sortByDateBS(filtered, sortOrderBS);
    }
    case "docId": {
      return sortByDocIdBS(filtered, sortOrderBS);
    }
    case null: {
      return filtered;
    }
    default: {
      console.log("Ошибка, proccessDataBS дефолтный свитч-кейс");
      return [];
    }
  }
}

///////////////////////////// ФИЛЬТРЫ
function filterByContragentsBS(data: any[], contragents: any[]) {
  return data.filter(
    (item) =>
      contragents.includes(item.payer) || contragents.includes(item.reciever),
  );
}

function filterBySearchTextBS(data: any[], text: string) {
  return data.filter(
    (item: any) =>
      String(item.number).includes(String(text)) ||
      String(item.recieverinn).toString().includes(String(text)) ||
      String(item.sum).includes(String(text)),
  );
}

function filterByPayTypeBS(data: any[], pickedTypes: string[]) {
  return data.filter((item: any) => {
    return pickedTypes.includes(item.type);
  });
}

function filterByOperTypeBS(data: any[], pickedTypes: string[]) {
  return data.filter((item: any) => pickedTypes.includes(item.operType));
}

///////////////////////////// СОРТИРОВКИ
function sortByDateBS(data: any[], order: "asc" | "desc") {
  return data.sort((a: any, b: any) => {
    const dateA = new Date(
      parseInt(a.docdate.split(".")[2]), // Year
      parseInt(a.docdate.split(".")[1]) - 1, // Month (subtract 1 as it is zero-based)
      parseInt(a.docdate.split(".")[0]), // Day
    ).getTime();
    const dateB = new Date(
      parseInt(b.docdate.split(".")[2]), // Year
      parseInt(b.docdate.split(".")[1]) - 1, // Month (subtract 1 as it is zero-based)
      parseInt(b.docdate.split(".")[0]), // Day
    ).getTime();

    return order === "asc" ? dateA - dateB : dateB - dateA;
  });
}

function sortByContragentBS(data: any[], order: "asc" | "desc") {
  return data.sort((a: any, b: any) => {
    const contragentA = (a.reciever || a.payer).toLowerCase(); // не отрабатывает на пустых значениях, бекер сказал, что их не будет
    const contragentB = (b.reciever || b.payer).toLowerCase();

    return order === "asc"
      ? contragentA.localeCompare(contragentB)
      : contragentB.localeCompare(contragentA);
  });
}

function sortByDocIdBS(data: any[], order: "asc" | "desc") {
  return data.sort((a: any, b: any) => {
    const numberA = a.number;
    const numberB = b.number;

    return order === "asc" ? numberA - numberB : numberB - numberA;
  });
}
