import {
  fetchPayroll,
  fetchContract,
  fetchHold,
  getHoldsFetch,
  getContractsFetch,
  deleteContractFetch,
  deleteHoldFetch,
  getUserHoldsFetch,
  getUserContractsFetch,
  updatePayrolFetch,
} from "api";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../../store/rootStore";

export class SalaryFundStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  ////api
  isLoadingFund: boolean = false;
  isModalLoadingFund: boolean = false;
  payrollData = [];
  isButtonVisibleFund: boolean = false;
  pickedEmployeeId: number | null = null;
  pickedContractId: number | null = null;
  draftPrice: string | null = "";
  holdsOptions = [];
  contractsOptions = [];
  contractsList: any[] = [];
  pickedTitle: string = "";
  pickedContract: string = "";
  isContractsModalPicked: boolean = false;

  selectedDate: Date = new Date();
  setSelectedDate = (date: any) => {
    this.selectedDate = date;
  };

  setIsLoadingFund = (bool: boolean) => {
    this.isLoadingFund = bool;
  };
  setIsModalLoadingFund = (bool: boolean) => {
    this.isModalLoadingFund = bool;
  };
  setPayrollData = (arr: any) => {
    this.payrollData = arr;
  };
  setIsButtonsVisibleFund = (bool: boolean) => {
    this.isButtonVisibleFund = bool;
  };

  setPickedEmployeeId = (num: number | null) => {
    this.pickedEmployeeId = num;
  };
  setPickedContractId = (num: number | null) => {
    this.pickedContractId = num;
  };
  setDraftPrice = (str: string | null) => {
    this.draftPrice = str;
  };
  setHoldsOptions = (arr: any) => {
    this.holdsOptions = arr;
  };
  setContractsOptions = (arr: any) => {
    this.contractsOptions = arr;
  };
  setContractsList = (arr: any) => {
    this.contractsList = arr;
  };

  setPickedTitle = (val: string) => {
    this.pickedTitle = val;
  };

  setPickedContract = (val: string) => {
    this.pickedContract = val;
  };

  setIsContractsModalPicked = (val: boolean) => {
    this.isContractsModalPicked = val;
  };

  getPayroll = async () => {
    this.setIsLoadingFund(true);
    const response = await fetchPayroll(this.selectedDate);
    this.setIsLoadingFund(false);
    if (response) {
      this.setPayrollData(response);
      this.setIsButtonsVisibleFund(!response[0].isAccountingEntry);
    }
  };

  addContract = async () => {
    const response = await fetchContract(
      this.pickedEmployeeId,
      this.pickedContractId,
      this.draftPrice,
      new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        1,
      ).toLocaleDateString("ru-RU"),
      new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth() + 1,
        0,
      ).toLocaleDateString("ru-RU"),
    );
    if(response){
      this.getPayroll();
    }
  };

  addHold = async () => {
    await fetchHold(
      this.pickedEmployeeId,
      this.pickedContractId,
      this.draftPrice,
      new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        1,
      ).toLocaleDateString("ru-RU"),
      new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth() + 1,
        0,
      ).toLocaleDateString("ru-RU"),
    );
  };

  getHolds = async () => {
    const response = await getHoldsFetch();
    if (response) {
      this.setHoldsOptions(response.data);
    }
  };

  getContracts = async () => {
    const response = await getContractsFetch();
    if (response) {
      this.setContractsOptions(response.data);
    }
  };

  deleteContract = async (idToDelete: any) => {
    const response = await deleteContractFetch(idToDelete);
    if(response){
      this.getPayroll();
    }
  };

  deleteHold = async (idToDelete: any) => {
    await deleteHoldFetch(idToDelete);
  };

  getUserHolds = async (userId: any) => {
    this.setIsModalLoadingFund(true);
    const response = await getUserHoldsFetch(userId);
    if (response) {
      this.setContractsList(
        response.data.map((value: any) => ({
          sum: value.sum,
          title: value.hold.title,
          id: value.id,
        })),
      );
    }
    this.setIsModalLoadingFund(false);
  };

  getUserContracts = async (userId: any) => {
    this.setIsModalLoadingFund(true);
    const response = await getUserContractsFetch(
      userId,
      `${(this.selectedDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}.${this.selectedDate.getFullYear()}`,
    );
    if (response) {
      this.setContractsList(
        response.data.map((value: any) => ({
          sum: value.sum,
          title: value.contract.title,
          id: value.id,
        })),
      );
    }
    this.setIsModalLoadingFund(false);
  };

  updatePayrol = async (isSave: any, isAccountingEntry: any) => {
    await updatePayrolFetch(
      isSave,
      isAccountingEntry,
      this.payrollData,
      this.selectedDate,
    );
    await this.getPayroll();
  };
}
