import React from "react";
import { PopupModal } from "../../../widgets";
import { DropdownUI } from "../../../widgets/dropdown";
import { Button, EButtonVariant, EInputStyleVariant, EInputVariant, Input } from '../../../ui';
import { toJS } from "mobx";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { DeleteContractIcon } from "../icons";
import { EFontVariant } from '../../../assets/styles/enums';

const FundModal = observer(() => {
  const { RootStore } = useStore();
  const { SalaryFundStore } = RootStore;
  const {
    isModalLoadingFund,
    draftPrice,
    holdsOptions,
    contractsOptions,
    setPickedContractId,
    setDraftPrice,
    addContract,
    addHold,
    deleteContract,
    deleteHold,
    contractsList,
    setContractsList,
    pickedContract,
    pickedTitle,
    setPickedContract,
    isContractsModalPicked,
    selectedDate,
  } = SalaryFundStore;


  const onAddContract = () => {
    {
      isContractsModalPicked ? addContract() : addHold();
    }
    setContractsList([
      ...toJS(contractsList),
      {
        title: pickedContract,
        sum: draftPrice,
        id: toJS(contractsList).length + 1,
      },
    ]);
    setPickedContract("");
    setDraftPrice(null);
  }

  return (
    <PopupModal
      maxWidth={"560px"}
      title={pickedTitle}
      onSave={() => RootStore.setModalIsActive(false)}
    >
      <div className="fund-modal__wrapper">
        <div className="fund-modal__inputs">
          <div className="fund-modal__element">
            <span className="modal__lable title">
              {isContractsModalPicked ? "Контракт" : "Удержание"}
            </span>
            <DropdownUI
              height={"40px"}
              borderRadius={"10px"}
              shown={
                pickedContract
                  ? pickedContract
                  : isContractsModalPicked
                    ? "Выбери контракт"
                    : "Выбери удержание"
              }
              maxWidth={"297px"}
            >
              {contractsOptions &&
                holdsOptions &&
                (isContractsModalPicked ? contractsOptions : holdsOptions).map(
                  (value: any) => (
                    <li
                      onClick={(event) => {
                        setPickedContract(
                          (event.target as HTMLLIElement).innerText,
                        );
                        setPickedContractId(value.id);
                      }}
                      id={value.id}
                      title={value.title}
                      className="fund-modal__list-elem"
                    >
                      {value.title}
                    </li>
                  ),
                )}
            </DropdownUI>
          </div>
          <div className="fund-modal__element">
            <span className="modal__lable title">Сумма, ₽</span>
            <Input
              type={EInputVariant.text}
              variant={EInputStyleVariant.basicInput}
              height={"40px"}
              borderRadius={"10px"}
              maxWidth={"116px"}
              value={draftPrice ? draftPrice : ""}
              onChange={(event) => {
                const { value } = event.target as HTMLInputElement;

                if (value === "" || /^\d+$/.test(value)) {
                  setDraftPrice(value === "" ? null : value);
                }
              }}
            />
          </div>
          <Button
            className="salary-fund__add-button"
            variant={EButtonVariant.fillLightButton}
            height={"40px"}
            maxWidth={"91px"}
            borderRadius={"10px"}
            text="Добавить"
            onClick={onAddContract}
            disabled={
              pickedContract === "" ||
              draftPrice === null ||
              draftPrice.startsWith("0") ||
              selectedDate.setHours(0, 0, 0, 0) <
              new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)
            }
          />

        </div>
        {!isModalLoadingFund && toJS(contractsList).length > 0 && (
          <div className="fund-modal__contracts-wrapper">
            {toJS(contractsList).map((value: any, index: number) => (
              <div className="fund-modal__contract" key={index}>
                {value.title}
                <div
                  className="fund-modal__price"
                  onClick={(event: any) => {
                    setContractsList(
                      toJS(contractsList).filter(
                        ({ id }: any) => id !== parseInt(event.target.id),
                      ),
                    );

                    isContractsModalPicked
                      ? deleteContract(value.id)
                      : deleteHold(value.id);
                  }}
                  id={value.id}
                >
                  {value.sum}
                  <DeleteContractIcon />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="fund-modal__list"></div>
      </div>
    </PopupModal>
  );
});

export default FundModal;
