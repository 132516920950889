import React from 'react';
import { toJS } from 'mobx';
import { ContragentBS, OperationTypeItem } from '../../types';
import { Select } from 'antd';
import { useStore } from '../../../../store';
import { observer } from 'mobx-react-lite';

export const ContragentSubconto = observer(() => {
  const { RootStore:
    {
      EditStatementStore:
        {
          currentBankStatement,
          setCurrentBankStatement,
          contragentsList,
          setDealList,
          setPickedDealId,
        }
    }
  } = useStore();

  if(!currentBankStatement) return null


  return (
    <Select
      className={"search-select"}
      placeholder={"---"}
      showSearch
      optionFilterProp="children"
      value={currentBankStatement.subconto1}
      filterOption={(input, option) =>
        (option?.label ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      options={ contragentsList.map((value: ContragentBS) => ({
        label: value.title,
        value: value.contragent_id,
      }))}
      onChange={(value: string, option) => {
        const { label } = option as OperationTypeItem;
        setDealList([]);
        setPickedDealId(null);

        setCurrentBankStatement({
          ...toJS(currentBankStatement),
          contragent_id: +value,
          subconto1: label,
          subconto2: '',
        });
      }}
    />
  );
});

