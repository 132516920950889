import { Filters } from "ui/filters";
import { useStore } from "store";
import { PeriodFilterBS } from "./period";
import { PayTypeFilterBS } from "./payType";
import { ContragentsFilterBS } from "./contragents";
import { SearchInputBS } from "./search";

export const BankStatementsFilters = () => {
  const { RootStore } = useStore();
  const { BankStatementsStore } = RootStore;
  const { isFilterDefault, resetFiltersBS } = BankStatementsStore;

  return (
    <div className="statements__underheader">
      <Filters
        haveResetButton={!isFilterDefault}
        isResetActive={!isFilterDefault}
        filtersReset={resetFiltersBS}
      >
        <PeriodFilterBS />
        <ContragentsFilterBS />
      </Filters>

      <SearchInputBS />
    </div>
  );
};
