import "./styles.scss";
import { useEffect, useState } from "react";
import { BigSkeleton, Button, EButtonVariant } from "ui";
import { observer } from "mobx-react-lite";
import { EmptyTableBody } from "../../ui/empty-table-body";
import { StatementDetails } from "./components/details";
import { TableFooter } from "widgets/table-footer";
import { UploaderModalBS } from "./components/uploaderModal";
import { useStore } from "store";
import { BankStatementsFilters } from "./components/filters/filters";
import { TableRowBS } from "./components/row";
import { TableHeaderBS } from "./components/tableHeader";
import { Statement } from "./types";

export const BankStatementsPage = observer(() => {
  const { RootStore } = useStore();
  const { BankStatementsStore } = RootStore;
  const {
    isLoadingBS,
    proccessedDataBS,
    getInitialDataBS,
    currentPage,
    setCurrentPage,
    maxOperationsNumber,
    dataBS,
    setMaxOperationsNumber,
  } = BankStatementsStore;

  const [firstItem, setFirstItem] = useState<number>(1);
  const [lastItem, setLastItem] = useState<number>(1);

  useEffect(() => {
    if (dataBS.length === 0) getInitialDataBS();
  }, []);

  return (
    <div className="statements__wrapper">
      <div className="statements__header">
        <h2 className="statements__header__title">Выписки из банков</h2>
        <Button
          variant={EButtonVariant.fillButton}
          text={"Добавить выписку"}
          maxWidth={"165px"}
          onClick={() => RootStore.setModalIsActive(true)}
        />
      </div>
      <BankStatementsFilters />
      {isLoadingBS ? (
        <BigSkeleton />
      ) : (
        <div className="statements-table__wrapper">
          <TableHeaderBS />
          <div className="statements-table__body">
            {proccessedDataBS.length > 0 ? (
              proccessedDataBS
                .map((statement: Statement) => (
                  <TableRowBS rowData={statement} index={statement.id} />
                ))
                .slice(firstItem, lastItem)
            ) : (
              <EmptyTableBody />
            )}
          </div>
          {proccessedDataBS.length > 0 && (
            <TableFooter
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              maxOperationsNumber={maxOperationsNumber}
              setMaxOperationsNumber={setMaxOperationsNumber}
              firstItem={firstItem}
              lastItem={lastItem}
              setFirstItem={setFirstItem}
              setLastItem={setLastItem}
              itemsList={proccessedDataBS}
            />
          )}
        </div>
      )}
      <UploaderModalBS />
      <StatementDetails />
    </div>
  );
});
