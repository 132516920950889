import { Checkbox, Dropdown, Menu, Space } from "antd";
import { observer } from "mobx-react-lite";
import { CloseFilterIcon, FilterArrowIcon } from "pages/bank-statements/icons";
import { useStore } from "store";
import React from "react";
import { MenuInfo } from "rc-menu/lib/interface";

export const ContragentsFilterBS = observer(() => {
  const { RootStore } = useStore();
  const { BankStatementsStore } = RootStore;
  const {
    contragentsBS,
    pickedContragentsBS,
    setPickedContragentsBS,
    toggleContragentBS,
  } = BankStatementsStore;
  console.log(contragentsBS);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu
          style={{ height: "300px" }}
          onClick={(info: MenuInfo) => {
             toggleContragentBS(contragentsBS[+info.key]);
            }
          }
        >
          {contragentsBS.length > 0 &&
            contragentsBS.map((contragent: string, index: number) => (
              <Menu.Item key={index} title={contragent}>
                <Checkbox
                  checked={
                    pickedContragentsBS.length > 0
                      ? pickedContragentsBS.includes(contragent)
                      : undefined
                  }
                />
                {contragent}
              </Menu.Item>
            ))}
        </Menu>
      }
    >
      <Space className="cursor-pointer" style={{ gap: "4px" }} title={pickedContragentsBS.join(", ")}>
        {pickedContragentsBS.length > 0 ? (
          <>
            <span>{pickedContragentsBS.join(", ")}</span>
            <div className="clear-single-filter">
              <CloseFilterIcon onClick={() => setPickedContragentsBS([])} />
            </div>
          </>
        ) : (
          <>
            {" "}
            Контрагент
            <FilterArrowIcon />
          </>
        )}
      </Space>
    </Dropdown>
  );
});
