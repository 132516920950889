import { Upload } from "antd";
import { PopupModal } from "widgets";
import { dragIcon, fileIcon, RemoveFileIcon } from "../icons";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
export const UploaderModalBS = observer(() => {
  const {
    RootStore: {
      BankStatementsStore: { uploadStatements, setFile, file, isUploading },
    },
  } = useStore();

  return (
    <PopupModal
      title={"Добавить выписку"}
      subtitle={"Здесь вы можете загрузить выписку из банка"}
      onSave={() => uploadStatements()}
      maxWidth={"440px"}
      isButtonDisabled={isUploading}
    >
      <div style={{ width: "100%", padding: "8px 20px" }}>
        <Upload.Dragger
          className={`${file ? "disabled" : null}`}
          disabled={file !== null}
          name="file"
          multiple={false}
          showUploadList={false}
          accept=".txt"
          action={undefined}
          customRequest={(e) => {
            setFile(e.file);
          }}
        >
          <p className="ant-upload-drag-icon">{dragIcon}</p>
          <p className="ant-upload-text">
            Перетащите в эту область или <span>загрузите</span> файл формата
            .txt
          </p>
        </Upload.Dragger>
        {file && (
          <div className="attachment">
            {fileIcon}
            <div>
              {file.name}
              <span>{(file.size / 1024 / 1024).toFixed(3)} Mb</span>
            </div>
            <RemoveFileIcon onClick={() => setFile(null)} />
          </div>
        )}
      </div>
    </PopupModal>
  );
});
