import React, {useEffect, useState} from 'react';
import cls from "./styles.module.scss";
import {EmployeesTable} from "./components/employee-table/employeesTable";
import {getAvailableUser, userGetRolesOnSpot} from "api/roles";
import {observer} from "mobx-react-lite";
import {useStore} from "store";
import {autorun} from "mobx";
import {IEmployeeData} from "pages/access-rights/types";

export const AccessRights = observer(() => {

  const { RootStore } = useStore();
  const { RoleStore } = RootStore;

  const [users, setUsers] = useState<IEmployeeData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getAvailableUser().then(res => {
      setUsers(res.data);
      setIsLoading(false);
    }).catch();
  }, [RoleStore]);

  return (
    <div className={cls.accessRightsPage}>
      <EmployeesTable isLoading={isLoading} users={users} />
    </div>
  );
});
