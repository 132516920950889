import React, { useEffect, useState } from "react";
import "./styles.scss";
import { BigSkeleton, Button, EButtonVariant } from 'ui';
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { Select } from "antd";
import { modalContent } from "widgets/popup-modal/modalContent";
import { BackButton } from "./icons";
import { PopupModal } from "widgets";
import { useStore } from "store";
import { DealBS, DealTypeItem, Statement } from '../types';
import { toJS } from "mobx";
import { BlockerAlert } from "ui/blocker-alert";
import { OperationType, PaymentType, OperationTypeItem } from "../types";
import { ContragentSubconto } from './components/ContragentSubconto';
import { BaristaSubconto } from './components/BaristaSubconto';
import { EFontVariant } from '../../../assets/styles/enums';

export const BankStatementEditPage = observer(() => {
  const { RootStore } = useStore();
  const { BankStatementsStore, EditStatementStore } = RootStore;
  const { isLoadingBS, proccessedDataBS, getInitialDataBS, dataBS } =
    BankStatementsStore;
  const {
    currentBankStatement,
    setCurrentBankStatement,
    setInitialBankStatement,
    dealsList,
    isBankStatementNotChanged,
    operationTypesList,
    paymentTypesList,
    updateBankStatement,
    setPickedDealId,
    setPickedOperationTypeId,
    setPickedPaymentTypeId,
    operationTypesListObject,
    setOperationTypesList,
    setPickedSubcontoType,
    firstSubconto,
    secondSubconto,
    getContragents,
    getBaristas,
    getPaymentType,
    isUpdating,
    getOperationType
  } = EditStatementStore;

  const store = RootStore;
  const navigate = useNavigate();
  const location = useLocation();
  const [modalType, setModalType] = useState<"save" | "quit" | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const bankStatement = toJS(dataBS).find(
      (statement: Statement) => statement.id === +location.hash.slice(1),
    );
    setCurrentBankStatement(bankStatement);
    setInitialBankStatement(bankStatement);
  }, [dataBS]);

  useEffect(() => {
    if (proccessedDataBS.length === 0) {
      getInitialDataBS().then(() => getOperationType());
    }
    else{
      getOperationType()
    }
    const bankStatement = proccessedDataBS.find(
      (statement: Statement) => statement.id === +location.hash.slice(1),
    );
    getBaristas();
    getContragents();
    setCurrentBankStatement(bankStatement);
    setInitialBankStatement(bankStatement);
    getPaymentType();

  }, []);

  return (
    <>
      <BlockerAlert
        isBlocking={!isBankStatementNotChanged}
        title={"Выйти из операции"}
        content={
          "Вы уверены, что хотите выйти из операции, все не сохраненные данные будут удалены"
        }
      />
      {isLoadingBS ? (
        <>
          <BigSkeleton padding="16px" />
        </>
      ) : (
        <>
          <div className="bs-edit__header">
            <div className="breadcrumbs">
              <p className="grey" onClick={() => navigate("/main/statements/")}>
                Выписка из банка /
              </p>
              <p className="black">Платежное поручение</p>
            </div>
            <div className="title">
              <div className="text">
                <div className="backlink">
                  <BackButton onClick={() => navigate("/main/statements/")} />
                </div>
                Платежное поручение
              </div>
              <Button
                variant={EButtonVariant.fillButton}
                font={EFontVariant.body2Regular}
                height={"32px"}
                maxWidth={"100px"}
                borderRadius={"10px"}
                text="Сохранить"
                onClick={() => {
                  setModalType("save");
                  store.setModalIsActive(true);
                }}
                disabled={isUpdating || isBankStatementNotChanged}
              />
            </div>
          </div>
          <div className="bs-edit__main">
            {!isLoading && currentBankStatement ? (
              <>
                <div className="st-info__block">
                  <div className="st-info__row">
                    <div className="st-info__column">
                      <div className="st-info__element">
                        <div className="st-info__title">Дата проведения</div>
                        <div className="st-info__value">
                          {toJS(currentBankStatement).outdate ||
                            toJS(currentBankStatement).indate}
                        </div>
                      </div>
                    </div>
                    <div className="st-info__column">
                      <div className="st-info__element">
                        <div className="st-info__title">№ Документа</div>
                        <div className="st-info__value">
                          {toJS(currentBankStatement).number}
                        </div>
                      </div>
                    </div>

                    <div className="st-info__column">
                      <div className="st-info__element">
                        <div className="st-info__title">Сумма</div>
                        <div className="st-info__value">
                          {toJS(currentBankStatement).sum}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="st-info__block">
                  <div className="st-info__row">
                    <div className="st-info__column">
                      {toJS(currentBankStatement).contragent_id && <div className='st-info__element'>
                        <div className='st-info__title'>
                          Контрагент
                        </div>
                        <div className='st-info__value'>
                          {toJS(currentBankStatement).subconto1}
                        </div>
                      </div>}
                    </div>
                    <div className="st-info__column">
                      <div className="st-info__element">
                        <div className="st-info__title">СЧЕТ </div>
                        <div className="st-info__value">
                          {toJS(currentBankStatement).recieveraccount ||
                            toJS(currentBankStatement).payeraccount}
                        </div>
                      </div>
                      <div className="st-info__element">
                        <div className="st-info__title">ИНН</div>
                        <div className="st-info__value">
                          {toJS(currentBankStatement).recieverinn ||
                            toJS(currentBankStatement).payerinn}
                        </div>
                      </div>
                    </div>
                    <div className="st-info__column">
                      <div className="st-info__element">
                        <div className="st-info__title">Корсчет</div>
                        <div className="st-info__value">
                          {toJS(currentBankStatement).recieverfixaccount ||
                            toJS(currentBankStatement).payerfixaccount}
                        </div>
                      </div>
                      <div className="st-info__element">
                        <div className="st-info__title">БИК</div>
                        <div className="st-info__value">
                          {toJS(currentBankStatement).recieverbik ||
                            toJS(currentBankStatement).payerbik}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="st-info__block">
                  <div className="st-info__row">
                    <div className="st-info__element">
                      <div className="st-info__title">Назначение платежа</div>
                      <div className="st-info__value">
                        {toJS(currentBankStatement).paydirection}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="edit-grid">
                  <div className="header__operation-type ">
                    <p className="header__operation-type__title ">
                      Вид платежа
                    </p>
                  </div>
                  <div className="header__payment-type ">
                    <span className="divider" />
                    <p className="header__operation-type__title ">
                      Вид операции
                    </p>
                  </div>
                  <div className="header__comission ">
                    <span className="divider" />
                    <p className="header__operation-type__title ">Комиссия</p>
                  </div>


                  <div className="payment-type">
                    <Select
                      className={"search-select"}
                      placeholder={"плейсхлд"}
                      optionFilterProp="children"
                      value={currentBankStatement.operation_type}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={operationTypesList.map(
                        (operation: OperationType) => ({
                          label: operation.title,
                          value: operation.id,
                          type: operation.contragent_type,
                        }),
                      )}
                      onChange={(value: string, option) => {
                        const { label, type } = option as OperationTypeItem;
                        setCurrentBankStatement({
                          ...toJS(currentBankStatement),
                          operation_type: label,
                          contragent_id: null,
                          subconto1: "",
                          subconto2: "",
                          comission: null,
                          contragent_type: type,
                        });
                        setPickedOperationTypeId(+value);
                        setPickedDealId(null);
                      }}
                    />
                  </div>
                  <div className="operation-type">
                    <Select
                      className={"search-select"}
                      placeholder={"---"}
                      optionFilterProp="children"
                      value={currentBankStatement.paytype}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={paymentTypesList.map((payment: PaymentType) => ({
                        label: payment.title,
                        value: payment.id,
                      }))}
                      onChange={(value: string, option) => {
                        const { label } = option as OperationTypeItem;

                        setCurrentBankStatement({
                          ...toJS(currentBankStatement),
                          paytype: label,
                          operation_type: "",
                          type: label === "Поступление" ? "in" : "out",
                          subconto2: '',
                          subconto1: '',
                          contragent_type: 'none'
                        });
                        setPickedPaymentTypeId(+value);
                        setOperationTypesList(label === "Поступление" ? operationTypesListObject.in : operationTypesListObject.out)
                      }}
                    />
                  </div>
                  {toJS(currentBankStatement).paytype.toLowerCase() ===
                    "поступление" &&
                  toJS(currentBankStatement).operation_type.toLowerCase() ===
                    "от эквайера" ? (
                    <div className="comission">
                      <input
                        className="comission-input"
                        type="text"
                        pattern="^[\d.]+$"
                        value={toJS(currentBankStatement).comission ?? ""}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if(inputValue === ''){
                            setCurrentBankStatement({
                              ...toJS(currentBankStatement),
                              comission: 0,
                            });
                          }
                          if (/^\d{1,}(\.\d{0,4})?$/.test(inputValue)) {
                            setCurrentBankStatement({
                              ...toJS(currentBankStatement),
                              comission: +inputValue,
                            });
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div className="comission disabled">-</div>
                  )}

                  <div className="edit-grid__empty "></div>
                  <div className="subcont1">
                    {
                      toJS(currentBankStatement).contragent_type === 'contragent' && <ContragentSubconto />
                    }
                    {
                      toJS(currentBankStatement).contragent_type === 'user' && <BaristaSubconto />
                    }
                    {
                      toJS(currentBankStatement).contragent_type === 'none' && <p className='none'>-</p>
                    }
                  </div>
                  <div className="subcont2">
                    {toJS(currentBankStatement).contragent_type === 'contragent' && dealsList && (
                      <Select
                        className={"search-select"}
                        showSearch
                        placeholder={"---"}
                        optionFilterProp="children"
                        value={toJS(currentBankStatement).subconto2}
                        onChange={(value: string, option) => {
                          const { label } = option as DealTypeItem;

                          setCurrentBankStatement({
                            ...toJS(currentBankStatement),
                            subconto2: label,
                          });

                          setPickedDealId(+value);

                        }}
                        onSearch={() => {}}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={dealsList.map((deal: DealBS) => ({
                          label: deal.number,
                          value: deal.id,
                        }))}
                      />)}
                    {toJS(currentBankStatement).contragent_type === 'contragent' && !dealsList && <p>{toJS(currentBankStatement).subconto2}</p>}
                    {toJS(currentBankStatement).contragent_type !== 'contragent'  && <p className='none'>-</p>}


                  </div>
                </div>

                <PopupModal
                  title={"Сохранить операцию?"}
                  backButton
                  backButtonText={"Нет"}
                  buttonText={"Да"}
                  onSave={() => {
                    updateBankStatement().then(() => getInitialDataBS());
                    store.setModalIsActive(false);
                  }}
                  maxWidth={"440px"}
                >
                  {modalContent(modalType!)}
                </PopupModal>
              </>
            ) : (
              <BigSkeleton />
            )}
          </div>
        </>
      )}
    </>
  );
});
