import { api } from "./api";
import { Value } from "react-calendar/src/shared/types";
import { dateToNumbersAndDots } from '../assets/utils';

export async function fetchStatementsBS(
  startDate: Value,
  endDate: Value,
  contragents: any[],
  statementTypes: any[],
) {
  const params: Record<string, any> = {};

  if (startDate !== null) params.date_start = dateToNumbersAndDots(startDate);
  if (endDate !== null) params.date_finish = dateToNumbersAndDots(endDate);
  if (contragents.length > 0) params.contragent = contragents;
  if (statementTypes.length > 0) params.type = statementTypes;


  try {
    return await api.post(`bank_statement/get`, params);
  } catch (e) {
    return null;
  }
}

export async function fetchOutPayment(guid: string) {
  try {
    return await api.get(`outgoingPaymentOrder/get`, {
      params: {
        guid,
      },
    });
  } catch (e) {
    return null;
  }
}

export async function fetchRecieversBS() {
  try {
    return api.get(`reciever/get`);
  } catch (e) {
    return null;
  }
}

export async function fetchPayersBS() {
  try {
    return api.get(`payer/get`);
  } catch (e) {
    return null;
  }
}

export async function fetchDeals(contragentId: number | null) {
  if (contragentId === null) return;
  try {
    return api.get(`bank_statement/payment/deals/list`, {
      params: {
        contragent_id: contragentId,
      },
    });

  } catch (e) {
    return null;
  }
}

export async function fetchOperationType() {
  try {
    return api.get(`bank_statement/operation/list`);
  } catch (e) {
    return null;
  }
}

export async function fetchPaymentType() {
  try {
    return api.get(`bank_statement/payment/list`);
  } catch (e) {
    return null;
  }
}

export async function fetchInitialDataBS(  startDate: Value,
                                           endDate: Value) {
  let proccessedResponse = {};
  await Promise.all([
    fetchRecieversBS(),
    fetchPayersBS(),
    fetchStatementsBS(startDate, endDate, [], []),
  ]).then((values) => {
    let contragents: any[] = []; // объединяем отправителей и получателей в контрагентов
    values[0]?.data.data.forEach((reciever: any) =>
      contragents.push(reciever.reciever),
    );
    values[1]?.data.data.forEach((payer: any) => contragents.push(payer.payer));
    proccessedResponse = {
      contragents,
      statements: values[2]?.data.data,
    };
  });
  return proccessedResponse;
}

export async function sendStatements(formdata: any) {
  try {
    return api.post(`bank_statement/send`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (e) {
    return null;
  }
}

export async function fetchUpdateBankStatement(
  statement_id: number,
  deal_id: number | null,
  operation_type_id: number | null,
  payment_type_id: number | null,
  contragent_id: number | null,
  comission: number | null,
) {
  try {
    return api.get(`bank_statement/update`, {
      params: {
        statement_id: statement_id,
        deal_id: deal_id,
        operation_type_id: operation_type_id,
        payment_type_id: payment_type_id,
        contragent_id: contragent_id,
        comission: comission,
      },
    });
  } catch (e) {
    return null;
  }
}

export async function fetchBankStatementsContragents() {
  try {
    return api.get(`contragents/companies/get`);
  } catch (e) {
    return null;
  }
}

export async function fetchBankStatementsBarista() {
  try {
    return api.get(`contragents/user/get`);
  } catch (e) {
    return null;
  }
}
