import React from 'react';
import { toJS } from 'mobx';
import { BaristaBS, ContragentBS, OperationTypeItem } from '../../types';
import { Select } from 'antd';
import { useStore } from '../../../../store';
import { observer } from 'mobx-react-lite';

export const BaristaSubconto = observer(() => {
  const { RootStore:
    {
      EditStatementStore:
        {
          currentBankStatement,
          setCurrentBankStatement,
          baristasList,
        }
    }
  } = useStore();

  if(!currentBankStatement) return null


  return (
    <Select
      className={"search-select"}
      placeholder={"---"}
      showSearch

      optionFilterProp="children"
      value={currentBankStatement.subconto1}
      filterOption={(input, option) =>
        (option?.label ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      options={ baristasList.map((value: BaristaBS) => ({
        label: `${value.surname} ${value.name}`,
        value: value.user_contragent_id,
      }))}
      onChange={(value: string, option) => {
        const { label } = option as OperationTypeItem;
        setCurrentBankStatement({
          ...toJS(currentBankStatement),
          contragent_id: +value,

          subconto1: label,
        });
      }}
    />
  );
});

