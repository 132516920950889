export const BackButton = ({ onClick }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick()}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.22702 11.4697L15.7123 2.98438L16.773 4.04504L8.81801 12L16.773 19.9549L15.7123 21.0156L7.22702 12.5303C7.08637 12.3897 7.00735 12.1989 7.00735 12C7.00735 11.8011 7.08637 11.6103 7.22702 11.4697Z"
        fill="#212129"
      />
    </svg>
  );
};
