// Добавляет пробелы в миллионах и тысячах

import { subDays, subMonths } from "date-fns";
import { RefObject } from "react";
import { Value } from "react-calendar/src/shared/types";

export const monthsNames: { [key: string]: string } = {
  "01": "Январь",
  "02": "Февраль",
  "03": "Март",
  "04": "Апрель",
  "05": "Май",
  "06": "Июнь",
  "07": "Июль",
  "08": "Август",
  "09": "Сентябрь",
  "10": "Октябрь",
  "11": "Ноябрь",
  "12": "Декабрь",
};
export const weekDaysShort: string[] = [
  "Вс",
  "Пн",
  "Вт",
  "Ср",
  "Чт",
  "Пт",
  "Сб",
];

/**
 * WithSpaces - с пробелами. Добавляет пробелы после тысяч.
 * @param {string | number} x - Число на входе
 */
export function addSpaces(x: number | string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

/** Возвращает день недели в формате "Вс" из даты формата "12.12.2022" */
export function getWeekDay(dateString: string): string {
  const date = new Date(
    Number(dateString.slice(6, 11)),
    Number(dateString.slice(3, 5)) - 1,
    Number(dateString.slice(0, 2))
  );
  return weekDaysShort[date.getDay()];
}

// TODO сменить название на monthOrderToShortRuMonthName
export const formatMonthYear = (locale: string | undefined, date: Date) =>
  [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ][date.getMonth()];

export const formatFullMonth = (locale: string, date: Date) =>
  [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ][date.getMonth()];

export const todayString = (): string => {
  return `${
    String(new Date().getDate()).length === 1
      ? `0${new Date().getDate()}`
      : new Date().getDate()
  }.${
    String(new Date().getMonth() + 1).length === 1
      ? `0${new Date().getMonth() + 1}`
      : new Date().getMonth() + 1
  }.${new Date().getFullYear()}`;
};

// utc to locale
export function convertToLocaleTimeString(utcTimeString: string) {
  return new Date(
    Date.UTC(
      2000,
      0,
      1,
      Number(utcTimeString.split(":")[0]),
      Number(utcTimeString.split(":")[1])
    )
  ).toLocaleTimeString("ru-RU", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
}

/** Принимает объект Date и возвращает строку формата 02.2023 (месяц и год) */
export function dateToMonthYear(date: Date) {
  return `${
    String(date.getMonth() + 1).length === 1
      ? `0${date.getMonth() + 1}`
      : date.getMonth() + 1
  }.${date.getFullYear()}`;
}

export function dateToFullRuMonthName(date: Date) {
  return date
    .toLocaleString("ru", { month: "long" })
    .replace(/^(\S)/, match => match.toUpperCase());
}

// 'dd.mm.yyyy'
export function dateToFullDate(date: Value) {
  return (date as Date).toLocaleString("ru", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function dropdownEscOrOutsideClickCloser(
  ref: RefObject<HTMLDivElement>,
  hide: () => void
) {
  function handleClickOutside(event: Event) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      hide();
    }
  }
  function handleEscapeKey(event: KeyboardEvent) {
    if (event.key === "Escape") {
      hide();
    }
  }
  document.addEventListener("mousedown", handleClickOutside);
  document.addEventListener("keydown", handleEscapeKey);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
    document.removeEventListener("keydown", handleEscapeKey);
  };
}

export function dateToNumbersAndDots(date: Value) {
  if (!date) return "";
  return (date as Date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(/\//g, ".");
}

export function toggleArrElem(
  elem: string,
  arr: Array<typeof elem>
): Array<typeof elem> {
  const newArr = [...arr];
  if (newArr.includes(elem)) {
    newArr.splice(newArr.indexOf(elem), 1);
  } else {
    newArr.push(elem);
  }
  return newArr;
}

function isValidDate(dateString: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
}

/** Принимает строку формата 31.12.2023 и возвращает строку в формате 31.12.23 */
export function remove20(dateStr: string): string {
  return dateStr.slice(0, 6) + dateStr.slice(8);
}

export function defaultRound(number: number): number | string {
  return number % 1 === 0 ? number : number.toFixed(2);
}

function isObject(object: any) {
  return object != null && typeof object === "object";
}

export function isDeepEqual<T>(prev_object: T, next_object: T) {
  const objKeys1 = Object.keys(prev_object as Object);
  const objKeys2 = Object.keys(next_object as Object);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    const value1 = (prev_object as any)[key];
    const value2 = (next_object as any)[key];

    const isObjects = isObject(value1) && isObject(value2);

    if (
      (isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
}

const DAY_IN_MINUTES = 24 * 60;
const HOUR_IN_MINUTES = 60;
export const getDurationFromHHMM = (at: string, to: string) => {
  const parseAt = at.split(":").map(str => Number(str));
  const parseTo = to.split(":").map(str => Number(str));

  let atMinutes = parseAt[0] * HOUR_IN_MINUTES + parseAt[1];
  let toMinutes = parseTo[0] * HOUR_IN_MINUTES + parseTo[1];

  if (toMinutes < atMinutes) toMinutes += DAY_IN_MINUTES;

  const durationInMinutes = toMinutes - atMinutes;

  return `${Math.floor((durationInMinutes * 10) / 60) / 10}`;
};
