import { observer } from 'mobx-react-lite';
import { FC,useCallback,useEffect,useState } from 'react';
import cls from '../styles.module.scss';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
import { useStore } from 'store';
import { toJS } from 'mobx';

let timer: number;

interface DateSelectorProps {
}

const DateSelectorComponent: FC<DateSelectorProps> = () => {
  const { RootStore: { SchedulePlanningStore: { selectedDate,setSelectedDate } } } = useStore();
  const [currentDate,setCurrentDate] = useState(selectedDate);

  useEffect(() => {
    timer = window.setTimeout(() => setSelectedDate(currentDate),500);
    return () => window.clearTimeout(timer);
  },[currentDate]);

  const setMonth = useCallback((step: number) => {
    setCurrentDate(prev => {
      const newDate = new Date(prev);
      newDate.setMonth(prev.getMonth() + step);
      return newDate;
    });
  },[]);

  return (<div className={cls['month-selector']}>
    <button onClick={() => setMonth(-1)} className={cls['button-prev']}></button>
    <p>{format(toJS(currentDate),'LLLL yyyy',{ locale: ru })}</p>
    <button onClick={() => setMonth(+1)} className={cls['button-next']}></button>
  </div>);
};

export const DateSelector = observer(DateSelectorComponent);