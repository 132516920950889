import { api } from "./api";

export async function fetchPayroll(selectedDate: Date) {
  try {
    const formattedDate = `${(selectedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${selectedDate.getFullYear()}`;

    const response = await api.post(`payrollFund`, {
      date: formattedDate,
    });
    return response.data.data;
  } catch (error: any) {
    return null;
  }
}
// добавление контракта
export async function fetchContract(
  pickedEmployeeId: number | null,
  pickedContractId: number | null,
  draftPrice: string | null,
  dateStart: string | null,
  dateFinish: string | null,
) {
  try {
    const response = await api.post(`payrollFund/contract/add`, {
      employee_id: pickedEmployeeId,
      contract_id: pickedContractId,
      value: draftPrice,
      date_start: dateStart,
      date_finish: dateFinish,
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

// добавление удержания
export async function fetchHold(
  pickedEmployeeId: number | null,
  pickedContractId: number | null,
  draftPrice: string | null,
  dateStart: string | null,
  dateFinish: string | null,
) {
  try {
    const response = await api.post(`payrollFund/hold/add`, {
      employee_id: pickedEmployeeId,
      hold_id: pickedContractId,
      value: draftPrice,
      date_start: dateStart,
      date_finish: dateFinish,
    });

    const holdsResponse = await response.data();
  } catch (error) {
    return null;
  }
}

// получение списка удержаний

export async function getHoldsFetch() {
  try {
    const response = await api.get(`holds/get`);
    return response.data;
  } catch (error) {
    return null;
  }
}

// получение списка контрактов
export async function getContractsFetch() {
  try {
    const response = await api.get(`contracts/get`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

// удаление контракта
export async function deleteContractFetch(idToDelete: number) {
  try {
    const response = await api.delete(`contract/delete`, {
      data: {
        id: idToDelete,
      },
    });

    const fetchResponse = response.data;
  } catch (error) {
    return null;
  }
}

// удаление удержания
export async function deleteHoldFetch(idToDelete: number) {
  try {
    const response = await api.delete(`holds/delete`, {
      data: {
        id: idToDelete,
      },
    });
    return response.data;
  } catch (error) {
    return null;
  }
}

// получение удержаний конкретного юзера
export async function getUserHoldsFetch(userId: number) {
  try {
    const response = await api.get(`holds/my?user_id=${userId}`);
    return response.data;
  } catch (error) {
    return null;
  }
}

// получение контрактов конкретного юзера
export async function getUserContractsFetch(userId: number, month: string) {
  try {
    const response = await api.get(
      `contracts/my?user_id=${userId}&month=${month}`,
    );
    return response.data;
  } catch (error) {
    return null;
  }
}

// по кнопкам сохранить  \ начислить
export async function updatePayrolFetch(
  isSave: boolean,
  isAccountingEntry: boolean,
  payrollData: any,
  selectedDate: Date,
) {
  try {
    const response = await api.post(
      `payrollFund/${isSave ? "save" : "payoff"}`,
      {
        date: `${
          (selectedDate.getMonth() + 1).toString().length === 1
            ? `0${selectedDate.getMonth() + 1}`
            : selectedDate.getMonth() + 1
        }.${selectedDate.getFullYear()}`,
        isSave: isSave,
        isAccountingEntry: isAccountingEntry,
        data: payrollData.map((value: any) => ({
          id: value.id,
          managerBonus: value.managerBonus.sum,
          tax: value.tax.sum,
          OPS: value.OPS.sum,
          OMS: value.OMS.sum,
          VNiM: value.VNiM.sum,
          CoHC: value.CoHC.sum,
        })),
      },
    );
    return response.data;
  } catch (error) {
    return null;
  }
}
