export const modalContent = (modalType: string) => {
  switch (modalType) {
    case "quit": {
      return (
        <div style={{ padding: "8px 20px", width: "100%" }}>
          <p id="modal-text">
            Вы уверены, что хотите выйти? Все несохранённые данные будут
            удалены.
          </p>
        </div>
      );
    }
    case "save": {
      return (
        <div style={{ padding: "8px 20px", width: "100%" }}>
          <p id="modal-text">Вы уверены, что хотите сохранить изменения?</p>
        </div>
      );
    }
    default: {
      return (
        <div style={{ padding: "8px 20px", width: "100%" }}>
          <p id="modal-text">Ошибка. Неожиданное содержимое модала.</p>
        </div>
      );
    }
  }
};
