import { FC,ReactNode } from 'react';
import cls from './styles.module.scss';
interface ButtonsProps {
  className?: string;

  primaryButtonLabel: ReactNode;
  primaryButtonDisabled?: boolean;
  onPrimaryButtonClick: () => void;

  secondaryButtonLabel: ReactNode;
  secondaryButtonDisabled?: boolean;
  onSecondaryButtonClick: () => void;
}

const ButtonsComponent: FC<ButtonsProps> = ({ className,primaryButtonLabel,onPrimaryButtonClick,secondaryButtonLabel,onSecondaryButtonClick,primaryButtonDisabled,secondaryButtonDisabled,}) => {
  return (
    <div className={`${cls['modal__buttons-group']} ${className}`}>
      <button className={cls['button-secondary']} onClick={onSecondaryButtonClick} disabled={secondaryButtonDisabled}>{secondaryButtonLabel}</button>
      <button className={cls['button-primary']} onClick={onPrimaryButtonClick} disabled={primaryButtonDisabled}>{primaryButtonLabel}</button>
    </div>
  );
};

export const ModalButtons = ButtonsComponent;