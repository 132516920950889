import { useStore } from "store";
import { EInputStyleVariant, EInputVariant, Input } from "ui/input";
import React from "react";

export const SearchInputBS = () => {
  const { RootStore } = useStore();
  const { BankStatementsStore } = RootStore;
  const { searchTextBS, setSearchTextBS } = BankStatementsStore;

  function handleSearchChange(event: React.FormEvent<HTMLInputElement>) {
    const { value } = event.target as HTMLInputElement;
    setSearchTextBS(value);
  }

  return (
    <div className="statements__search">
      <Input
        type={EInputVariant.text}
        variant={EInputStyleVariant.searchInput}
        height={"32px"}
        maxWidth={"275px"}
        width={"230px"}
        borderRadius={"10px"}
        placeholder={"№ документа, сумма, ИНН"}
        withReset={true}
        value={searchTextBS}
        onChange={(e) => handleSearchChange(e)}
        onReset={() => {
          setSearchTextBS("");
        }}
      />
    </div>
  );
};
