import React from "react";
import { toJS } from "mobx";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { DotIcon } from "../icons";

const FundLeftTable = observer(() => {
  const { RootStore } = useStore();
  const { SalaryFundStore } = RootStore;
  const { payrollData } = SalaryFundStore;

  return (
    <div className="fund-table__container-wrapper">
      <div className="fund-table__container">
        <div className="fund-table__header">
          <div className="fund-table__header-element w40"></div>
          <div className="fund-table__header-element f248">Сотрудник</div>
          <div className="fund-table__header-element f128 bold">
            К выплате, ₽
          </div>
        </div>
        <div className="fund-table__body">
          {toJS(payrollData) &&
            toJS(payrollData).map((object: any, index: number) => (
              <div
                className="fund-table__body-row"
                key={object.id + index + index}
                id={object.id + index}
              >
                <div
                  className="fund-table__body-element w40"
                  key={`${object.id + index}-${0}`}
                  id={`${object.id + index}-${0}`}
                >
                  <DotIcon />
                </div>
                <div
                  className="fund-table__body-element f248"
                  key={`${object.id + index}-${1}`}
                  id={`${object.id + index}-${1}`}
                >
                  <span>{object.name}</span>
                </div>
                <div
                  className={`fund-table__body-element f128 bold`}
                  key={`${object.id + index}-${2}`}
                  id={`${object.id + index}-${2}`}
                >
                  <span>{object.toPayoff.sum}</span>
                </div>
              </div>
            ))}
        </div>
        <div className="fund-table__footer">
          <div className="fund-table__footer-element w40"></div>
          <div className="fund-table__footer-element f248">Всего</div>
          <div className="fund-table__footer-element f128">
            {toJS(payrollData)
              .map((obj: any) => obj.toPayoff.sum)
              .reduce((acc: any, val: any) => acc + val, 0)}
          </div>
        </div>
      </div>
    </div>
  );
});

export default FundLeftTable;
