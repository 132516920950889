import React from "react";
import { calculateTotalValue } from "../../utils";
import { toJS } from "mobx";
import { useStore } from "../../../../store";
import { observer } from "mobx-react-lite";

const FundRightTableFooter = observer(() => {
  const {
    RootStore: {
      SalaryFundStore: { payrollData },
    },
  } = useStore();

  return (
    <div className="fund-table__footer">
      {/*temp - scfin 946*/}
      {/*<div className="fund-table__footer-element f112">*/}
      {/*  {calculateTotalValue(toJS(payrollData), "paid")}*/}
      {/*</div>*/}
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "planHour")}
      </div>
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "factHour")}
      </div>
      <div className="fund-table__footer-element f140">
        {calculateTotalValue(toJS(payrollData), "reward")}
      </div>
      <div className="fund-table__footer-element f132">
        {calculateTotalValue(toJS(payrollData), "managerBonus")}
      </div>
      <div className="fund-table__footer-element f164">
        {calculateTotalValue(toJS(payrollData), "revenueBonus")}
      </div>
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "contracts")}
      </div>
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "tax")}
      </div>
      {/*<div className="fund-table__footer-element f100">*/}
      {/*  {calculateTotalValue(toJS(payrollData), "holds")}*/}
      {/*</div>*/}
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "OPS")}
      </div>
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "OMS")}
      </div>
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "VNiM")}
      </div>
      <div className="fund-table__footer-element f100">
        {calculateTotalValue(toJS(payrollData), "CoHC")}
      </div>
    </div>
  );
});

export default FundRightTableFooter;
