import React,{ useCallback,useEffect,useMemo,useRef,useState } from "react";
import "./styles.scss";
import { Button,EButtonVariant,ELogoVariant,Logo } from "ui";
import { useNavigate } from "react-router";
import palmIcon from "assets/icons/svg/palm.svg";
import { Notifications } from "../notifications";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { NotificationsIcon } from "./icons";
import { fetchLogout } from "api/header";
import Cookies from "js-cookie";
import { PopupModal } from "widgets";
import { Select } from "antd";
import { Nullable } from "assets/types";
import { userGetAvailableSpots,userSetSpot } from "api/roles";
import { IAvailableSpot } from "pages/access-rights/types";
import { DkkModal } from './components/dkk-modal';

export const Header = observer(() => {

  const { RootStore } = useStore();
  const { RoleStore: { fetchUserData,spotId },
    NotificationsStore,setIsAuthenticated,HeaderStore: { profileData,fetchGetProfile } } = RootStore;
  const navigate = useNavigate();
  const [isSetSpotModal,setIsSpotModal] = useState(false);
  const [selectData,setSelectData] = useState<IAvailableSpot[]>([]);
  const [selectedSpotId,setSelectedSpotId] = useState<Nullable<number>>(null);
  const notifRef = useRef<HTMLDivElement>(null);
  const notifIconRef = useRef<HTMLDivElement>(null);
  const [isAboutModalOpen,setIsAboutModalOpen] = useState(false);
  const [isEditDkkModalOpen,setIsEditDkkModalOpen] = useState(false);

  const LogoutFetch = async () => {
    try {
      const response = await fetchLogout();
      if (response.success) {
        Cookies.remove("token");
        setIsAuthenticated(false);
        navigate("/login/auth");
      }
    } catch (err) {
      return null;
    }
  };

  const openSetSpotModal = useCallback(() => {
    setIsSpotModal(true);
    RootStore.setModalIsActive(true);
  },[RootStore]);

  const saveSpot = useCallback(async (id: Nullable<number | string>) => {
    if (!id) {
      return;
    }

    await userSetSpot(+id);
    await fetchGetProfile();

    setIsSpotModal(false);

    window.location.reload();
  },[]);

  useEffect(() => {
    fetchGetProfile();
    NotificationsStore.getAllNTF();
    fetchUserData();
  },[]);

  useEffect(() => {
    if (!isSetSpotModal) {
      return;
    }

    if (spotId) {
      userGetAvailableSpots(spotId).then(res => setSelectData(res.data)).catch();
    }
  },[isSetSpotModal]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        notifRef.current &&
        !notifRef.current.contains(event.target as Node) &&
        !notifIconRef?.current?.contains(event.target as Node)
      ) {
        setNotificationsOpened(false);
      }
    }
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setNotificationsOpened(false);
      }
    }
    document.addEventListener("mousedown",(event: MouseEvent) =>
      handleClickOutside(event),
    );
    document.addEventListener("keydown",(event: KeyboardEvent) =>
      handleEscapeKey(event),
    );
    return () => {
      document.removeEventListener("mousedown",handleClickOutside);
      document.removeEventListener("keydown",handleEscapeKey);
    };
  });

  const today = new Date();
  const date = today.toLocaleDateString("ru-RU",{
    weekday: "short",
    day: "numeric",
    month: "long",
  });
  const weekday = date.substr(0,2); // extract the first two characters of the date string
  const capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1); // capitalize the first letter
  const formattedDate = capitalizedWeekday + date.substr(2);

  const [subMenuVision,setSubMenuVision] = useState(false);
  const [notificationsOpened,setNotificationsOpened] =
    useState<boolean>(false);

  // For testing.
  return (
    <div className="header__container">
      <div className="header__logo-container">
        <Logo variant={ELogoVariant.logo1_10} />
      </div>
      <div className="header__menu-container">
        <div className="header__date">{formattedDate}</div>
        <div className="header__notifications">
          <div
            className={
              notificationsOpened
                ? "header__notifications__bell--opened"
                : "header__notifications__bell"
            }
            onClick={() => setNotificationsOpened(!notificationsOpened)}
            ref={notifIconRef}
          >
            <NotificationsIcon unread={NotificationsStore.anyUnreadNotif} />
          </div>
          <Notifications notifRef={notifRef} isOpen={notificationsOpened} />
        </div>

        <div
          onMouseLeave={() => {
            setSubMenuVision(false);
          }}
          className="header__profile"
        >
          <div className="header__profile__pic">
            {profileData?.avatar ? (
              <img src={profileData?.avatar} alt="avatar" />
            ) : (
              <img src={palmIcon} alt="" />
            )}
          </div>
          <div className="header__profile__info">
            <span className={`header__profile__info-name`}>{profileData?.name}</span>
            <span className={`header__profile__info-organization`}>
              {profileData?.spot}
            </span>
          </div>
          <Button
            variant={EButtonVariant.triplePointsButton}
            maxWidth={"28px"}
            height={"28px"}
            onClick={() => {
              subMenuVision ? setSubMenuVision(false) : setSubMenuVision(true);
            }}
          // onPointerEnter ???
          />
          <div
            className={
              subMenuVision
                ? "header__profile__submenu"
                : "header__profile__submenu-vision"
            }
          >
            {(profileData as any)?.job_title === 'Управляющий' && <span
              className={`header__profile__submenu__item`}
              onClick={() => { setIsEditDkkModalOpen(true); RootStore.setModalIsActive(true); }}
            >
              Настроить часы по ДКК
            </span>}
            <span
              className={`header__profile__submenu__item`}
              onClick={() => { openSetSpotModal(); }}
            >
              Выбор спота
            </span>
            <span
              className={`header__profile__submenu__item`}
              onClick={() => { setIsAboutModalOpen(true); RootStore.setModalIsActive(true); }}
            >
              О программе
            </span>
            <span
              className={`header__profile__submenu__item `}
              onClick={() => {
                LogoutFetch();
              }}
            >
              Выйти
            </span>
          </div>
        </div>
      </div>
      {isSetSpotModal &&
        <PopupModal
          title="Выбор спота"
          onSave={async () => { await saveSpot(selectedSpotId); }}
          onClose={() => setIsSpotModal(false)}
          maxWidth={"440px"}
        >
          <div className="modalMainContent">
            <Select onChange={(id: number) => setSelectedSpotId(id)} placeholder="Выберите спот" className="spotSelect">
              {selectData.map((option: IAvailableSpot) => (
                <Select.Option key={option.id} value={option.id} label={option.spotName}>
                  {option.spotName}
                </Select.Option>
              ))}
            </Select>
          </div>
        </PopupModal>
      }
      {isAboutModalOpen &&
        <PopupModal
          title='О программе'
          onSave={() => { }}
          onClose={() => { setIsAboutModalOpen(false); }}
          maxWidth={'440px'}
          withoutSave
        >
          <div className="modalMainContent">
            <p className='about-modal__title'>Правообладатель</p>
            <p className='about-modal__info'>© 2024 АО «СОЛИД СЕССИОН ЖАНЕЙРО РЕНЕССАНС»</p>
          </div>
        </PopupModal>
      }
      {isEditDkkModalOpen &&
        <DkkModal onClose={() => setIsEditDkkModalOpen(false)} />
      }
    </div>
  );
});

// TODO: Апдейт текущего спота в хедер
