import {RootStore} from "store/rootStore";
import {Nullable} from "assets/types";
import {RoleUserData} from "pages/access-rights/types";
import {GetProfileFetch} from "api/header";
import {makeAutoObservable} from "mobx";

export class RoleStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  spotId: Nullable<number> = null;
  userId: Nullable<number> = null;
  name: Nullable<string> = null;
  surname: Nullable<string> = null;
  email: Nullable<string> = null;

  setUserData = (userData: RoleUserData) => {
    this.name = userData.name;
    this.surname = userData.surname;
    this.email = userData.email;
    this.userId = userData.id;
    this.spotId = userData.spot_id;
  }

  fetchUserData = async () => {
    const response = await GetProfileFetch();

    if (response?.data.data) {
      this.setUserData({
        id: response?.data.data.id,
        spot_id: response?.data.data.spot_id,
        email: response?.data.data.email,
        name: response?.data.data.name,
        surname: response?.data.data.surname,
      });
    }
  }
}
