import React,{ useCallback } from "react";
import { toJS } from "mobx";
import { Button,EButtonVariant } from "../../../ui";
import { EFontVariant } from "../../../assets/styles/enums";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";

//* Кнопки временно скрыты fundHeaderButtons is hidden
const BUTTONS_IS_VISIBLE = false;

export const FundHeader = observer(() => {
  const { RootStore } = useStore();
  const { SalaryFundStore } = RootStore;
  const {
    payrollData,
    isButtonVisibleFund,
    setIsButtonsVisibleFund,
    updatePayrol,
  } = SalaryFundStore;

  const onPayrollClick = useCallback(() => {
    updatePayrol(false,true);
    setIsButtonsVisibleFund(false);
  },[]);

  const onSaveClick = useCallback(() => {
    updatePayrol(true,false);
  },[]);

  return (
    <div className="fund__header">
      <div className="fund__title">Фонд оплаты труда </div>
      <div className="fund__header-buttons">
        {BUTTONS_IS_VISIBLE && toJS(payrollData) && isButtonVisibleFund && (
          <>
            <Button
              variant={EButtonVariant.fillButton}
              font={EFontVariant.body2Regular}
              height={"32px"}
              maxWidth={"100px"}
              borderRadius={"10px"}
              text="Начислить"
              onClick={onPayrollClick}
            />
            <Button
              variant={EButtonVariant.fillLightButton}
              font={EFontVariant.body2Regular}
              height={"32px"}
              maxWidth={"100px"}
              borderRadius={"10px"}
              text="Сохранить"
              onClick={onSaveClick}
            />
          </>
        )}
      </div>
    </div>
  );
});
