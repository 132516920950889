import { useStore } from "store";
import { useNavigate } from "react-router";
import { InPayIcon, OutPayIcon } from "../icons";
import { observer } from "mobx-react-lite";
import { Statement } from "../types";

export const TableRowBS = observer(
  ({ rowData, index }: { rowData: Statement; index: number }) => {
    const navigate = useNavigate();
    const {
      RootStore: {
        BankStatementsStore: { detailsId, setDetailsId },
      },
    } = useStore();

    function editStatement(id: number) {
      navigate(`/main/statements/edit#${id}`);
    }

    return (
      <div
        className="statements-table__body-row cursor-pointer"
        key={index}
        data-testid={"bs-elem"}
      >
        <div className="statements-table__body-element f144">
          <div
            className={`statements-table__date ${
              detailsId === index ? "statements-table__date--active" : ""
            }`}
            onClick={() => {
              if (detailsId === index) {
                setDetailsId(null);
              } else {
                setDetailsId(rowData.id);
              }
            }}
          >
            {rowData.outdate || rowData.indate}
          </div>
        </div>
        <div
          className="statements-table__body-element f144"
          onClick={() => editStatement(rowData.id)}
        >
          {rowData.number}
        </div>
        <div
          className="statements-table__body-element f284"
          onClick={() => editStatement(rowData.id)}
          title={rowData.payer || rowData.reciever}
        >
          <p> {rowData.payer || rowData.reciever}</p>
        </div>
        <div
          className="statements-table__body-element f144"
          onClick={() => editStatement(rowData.id)}
        >
          {rowData.type === "out" && <OutPayIcon />}
          {rowData.type === "in" && <InPayIcon />}
          {rowData.sum % 1 === 0 ? rowData.sum : rowData.sum.toFixed(2)}
        </div>
        <div
          className="statements-table__body-element f144 "
          onClick={() => editStatement(rowData.id)}
        >
          {rowData.type === "out" && "Списание"}
          {rowData.type === "in" && "Поступление"}
        </div>
        <div
          className="statements-table__body-element f284"
          onClick={() => editStatement(rowData.id)}
        >
          {rowData.operation_type}
        </div>
      </div>
    );
  },
);
