import React, {useEffect, useState} from "react";
import { Table } from "antd";
import {
  IEmployeeData,
  IMatrixRow, IMatrixSubRow,
  IPermissions,
  PermissionTypeKey,
  PermissionTypeValue, SendPermissions
} from "pages/access-rights/types";
import cls from "./styles.module.scss";
import {Nullable} from "assets/types";
import {PopupModal} from "widgets";
import {useStore} from "../../../../store";
import {userGetRolesOnSpot, userSetPermissionsOnSpot} from "../../../../api/roles";
import {RulesMatrix} from "../rules-matrix";
import {showAlertPopup} from "../../../../ui/alert";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const { Column } = Table;

interface EmployeesTableProps {
  users: IEmployeeData[];
  isLoading: boolean;
}

const PermissionLabels: Record<PermissionTypeKey, PermissionTypeValue> = {
  Invoice: "Накладная",
  Cash_Order: "Кассовый ордер",
  Manual_Input: "Ручной ввод",
  Advance_Report: "Авансовый отчет",
  Inventory: "Инвентаризация",
  Scheduling: "Планирование графика",
  Bank_Statements: "Банковские выписки",
}

export const EmployeesTable: React.FC<EmployeesTableProps> = (props) => {

  const {
    users,
    isLoading,
  } = props;

  const { RootStore } = useStore();

  const [currentUser, setCurrentUser] = useState<Nullable<IEmployeeData>>(null);
  const [isShownModal, setIsShownModal] = useState<boolean>(false);
  const [permissionCollection, setPermissionCollection] = useState<Nullable<IPermissions>>(null);
  const [sendData, setSendData] = useState<IMatrixRow[]>([]);

  const onClickHandler = async (user: IEmployeeData) => {

    const response = await userGetRolesOnSpot(user.spot_id, user.id);

    if (response?.data) {
      console.log(response?.data);
      setPermissionCollection(response?.data);
    }

    setCurrentUser(user);

    setIsShownModal(true);
    RootStore.setModalIsActive(true);
  }

  const onSaveAction = async () => {

    if (!currentUser?.id) {
      showAlertPopup("Ошибка", "Текущий пользователь не определен", "error");
      return;
    }

    const result: SendPermissions = { permissions: {}, user_id: null };

    sendData.forEach((item: IMatrixRow, index: number) => {
      item.rows.forEach((item: IMatrixSubRow, index: number) => {
        result.permissions[item.slug] = Number(item.value);
      });
    });

    result.user_id = currentUser?.id;
    await userSetPermissionsOnSpot(result);

    setIsShownModal(false);
    RootStore.setModalIsActive(false);
  }

  const handleInputChange = (e: IMatrixRow[]) => setSendData(e);

  const locale = { emptyText: "Данные отсутствуют" }

  return (
    <div>
      <Table loading={isLoading} dataSource={users} locale={locale}>
        <Column
          title="Имя"
          key="name"
          align="center"
          render={(_: any, user: IEmployeeData) => {
            return `${user.name} ${user.surname}`
          }}
        />
        <Column
          title="Должность"
          key="job_title"
          dataIndex="job_title"
          align="center"
        />
        <Column
          title="Опции"
          key="options"
          align="center"
          render={(_: any, user: IEmployeeData) => (
            user?.allow_edit_permissions &&
            <button
              onClick={async () => await onClickHandler(user)}
              className={cls.itemButton}
            >
              Настроить
            </button>
            || <span>Нет опций</span>
          )}
        />
      </Table>
      <div>
        {isShownModal &&
          <PopupModal
            title="Настройка прав пользователя"
            onSave={async () => { await onSaveAction() }}
            onClose={() => setIsShownModal(false)}
            isButtonDisabled={!currentUser?.id}
            maxWidth={"750px"}
          >
            {permissionCollection &&
              <div className="modalMainContent">
                <RulesMatrix
                  PermissionLabels={PermissionLabels}
                  onChange={handleInputChange}
                  data={permissionCollection}
                />
              </div>
            }
          </PopupModal>
        }
      </div>
    </div>
  )
}
