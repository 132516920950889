import React,{ useState,ReactNode,useEffect,useRef } from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { Dropdown,Menu,MenuProps,Pagination,Radio,Space } from "antd";

interface DropdownUIProps {
  firstItem: number;
  lastItem: number;
  setFirstItem: (arg0: number) => void;
  setLastItem: (arg0: number) => void;
  itemsList: any[]; //todo type
  currentPage: number;
  setCurrentPage: (arg0: number) => void;
  maxOperationsNumber: number;
  setMaxOperationsNumber: (arg0: number) => void;
}

type MaxOperationsNumberMenuItem = {
  label: string;
  key: number;
};

const maxOperationsNumberItems: MaxOperationsNumberMenuItem[] = [
  {
    label: "10",
    key: 10,
  },
  {
    label: "15",
    key: 15,
  },
  {
    label: "30",
    key: 30,
  },
  {
    label: "50",
    key: 50,
  },
  {
    label: "100",
    key: 100,
  },
];

export const TableFooter: React.FC<DropdownUIProps> = observer(
  ({
    firstItem,
    lastItem,
    setFirstItem,
    setLastItem,
    itemsList,
    currentPage,
    setCurrentPage,
    maxOperationsNumber,
    setMaxOperationsNumber,
  }) => {
    const indexOfLastItem = currentPage * maxOperationsNumber;
    const indexOfFirstItem = indexOfLastItem - maxOperationsNumber;

    useEffect(() => {
      if (firstItem !== 1 && lastItem !== 1) return;
      setCurrentPage(1);
    },[itemsList]);

    useEffect(() => {
      //if(firstItem !== 1 && lastItem !== 1) return
      setFirstItem(indexOfFirstItem);
      setLastItem(indexOfLastItem);
    },[indexOfFirstItem,indexOfLastItem]);

    const handleMenuClick = ({ key }: { key: string; }) => {
      setMaxOperationsNumber(+key);
    };

    const maxOperationsNumberMenu = (
      <Menu onClick={handleMenuClick}>
        {maxOperationsNumberItems.map((item) => (
          <Menu.Item key={item.key}>
            <Radio checked={maxOperationsNumber === item.key}>
              {item.label}
            </Radio>
          </Menu.Item>
        ))}
      </Menu>
    );

    const Shown = () => {
      if (itemsList.length === 1) return <>1 из 1</>;
      else {
        return (
          <>
            {itemsList.length === 0 ? 0 : indexOfFirstItem + 1}-
            {itemsList &&
              (indexOfLastItem > itemsList.length
                ? itemsList.length
                : indexOfLastItem)}{" "}
            из {itemsList && itemsList.length}
          </>
        );
      }
    };

    return (
      <div className="registry-table__footer">
        <div className="registry-table__footer-number">
          <div className="registry-table__footer-shown">
            <div className="registry-table__footer-shownTitle">
              Показано записей:
            </div>
            <div className="registry-table__footer-shownNumber">
              <Shown />
            </div>
          </div>
          <div className="registry-table__footer-options">
            <div className="registry-table__footer-optionsTitle">
              На странице:
            </div>
            <Dropdown overlay={maxOperationsNumberMenu} trigger={["click"]}>
              <Space className="cursor-pointer">
                <div className="registry-table__footer-options__pick">
                  {maxOperationsNumber}
                  {icon}
                </div>
              </Space>
            </Dropdown>
          </div>
          <div className="registry-table__page-button">
            <Pagination
              defaultCurrent={1}
              total={itemsList && itemsList.length}
              pageSize={maxOperationsNumber}
              current={currentPage}
              onChange={(current,size) => {
                setCurrentPage(current);
              }}
            />
          </div>
        </div>
      </div>
    );
  },
);

const icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8934 8.21141L10.2573 12.3778C10.2235 12.4165 10.1834 12.4473 10.1392 12.4682C10.0951 12.4892 10.0478 12.5 10 12.5C9.95222 12.5 9.9049 12.4892 9.86076 12.4682C9.81662 12.4473 9.77652 12.4165 9.74275 12.3778L6.10664 8.21141C6.05573 8.15314 6.02106 8.07888 6.007 7.99801C5.99295 7.91715 6.00015 7.83333 6.02769 7.75715C6.05524 7.68098 6.10189 7.61589 6.16174 7.57011C6.22158 7.52434 6.29194 7.49994 6.3639 7.5H13.6361C13.7081 7.49994 13.7784 7.52434 13.8383 7.57011C13.8981 7.61589 13.9448 7.68098 13.9723 7.75715C13.9998 7.83333 14.0071 7.91715 13.993 7.99801C13.9789 8.07888 13.9443 8.15314 13.8934 8.21141Z"
      fill="#212129"
    />
  </svg>
);
