import { useEffect,useMemo } from "react";
import { Route,Routes,useNavigate } from "react-router";
import { ReportsPage } from "pages/reports";
import { RevenuePlanningPage } from "pages";
import { Header,Sidebar } from "widgets";
import "./styles.scss";
import { SchedulePlanningPage } from "pages/spot/schedule-planning-new";
import { observer } from "mobx-react-lite";
import { RegistryDocument } from "pages/registry-document";
import { TimesheetPage } from "../../../pages/timesheet";
import { DashboardPage } from "pages/dashboard";
import { OperationInputEditPage } from "../../../pages/operation-input/operation-input-edit";
import { BankStatementEditPage } from "pages/bank-statements/statement-edit";
import { BankStatementsPage } from "pages/bank-statements";
import { SalaryFundPage } from "pages/salary-fund";
import { RegistryPage } from "pages/registry";
import { OperationInputPage } from "pages/operation-input";
import { FinancePage } from "../../../pages/finance";
import { useTour } from "@reactour/tour";
import { MainTourSteps } from "../../tours/MainTour/MainTour";
import { useStore } from "../../../store";
import ErrorBoundary from '../../errorBoundary/ErrorBoundary';
import { AccessRights } from "../../../pages/access-rights";

export const MainProcess = observer(() => {
  const {
    RootStore: { setIsAuthenticated,HeaderStore: { permissions } },
  } = useStore();

  //TODO: Костыль, переделать, когда будут реализовываться права доступа
  const canScheduleView = useMemo(() => {
    if (!permissions) return false;

    const schedulePermissions = permissions['Scheduling'];
    if (!schedulePermissions) return false;

    const viewPermission = !Object.entries(schedulePermissions).every(([key,value]) => !value.active);

    return viewPermission;

  },[permissions]);

  const navigate = useNavigate();

  const { setSteps } = useTour();
  useEffect(() => {

    window.addEventListener("storage",() => {
      navigate("/login/auth");
      setIsAuthenticated(false);
    });

    return () => {
      window.removeEventListener("storage",() => {
        navigate("/login/auth");
        setIsAuthenticated(false);
      });
    };
  },[]);

  useEffect(() => {
    //TODO: Костыль для быстрого добавления, избавиться от этого
    let steps = MainTourSteps;
    if (!canScheduleView) {
      steps = steps.filter(s => s.selector !== '.nav__schedule');
    }

    setSteps!(steps);
  },[canScheduleView]);

  return (
    <ErrorBoundary>
      <div className="mainProcess__wrapper">
        <Header />
        <div className="mainProcess__inner">
          <Sidebar />
          <div className="mainProcess__container">
            <Routes>
              <Route path="/spot" element={<RevenuePlanningPage />} />
              <Route path="/reports/*" element={<ReportsPage />} />
              <Route path="/schedule/*" element={<SchedulePlanningPage />} />
              <Route path="/registry" element={<RegistryPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/registry/*" element={<RegistryDocument />} />

              <Route path="/fund" element={<SalaryFundPage />} />
              <Route path="/statements" element={<BankStatementsPage />} />
              <Route
                path="/statements/edit"
                element={<BankStatementEditPage />}
              />
              <Route path="/timesheet" element={<TimesheetPage />} />
              <Route path="/input" element={<OperationInputPage />} />
              <Route path="/input/edit" element={<OperationInputEditPage />} />
              <Route path="/input/new" element={<OperationInputEditPage />} />
              <Route path="/finance" element={<FinancePage />} />
              <Route path="/access" element={<AccessRights />} />
            </Routes>
          </div>
        </div>
      </div>
    </ErrorBoundary>

  );
});
